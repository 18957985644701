<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalDateGame" :title="$t('addPlayTime')" @shown="init">
      <div class="form-group">
        <b-form-group :label="$t('selectDays')">
          <b-form-checkbox-group :options="days" name="days" switches stacked v-model="match.days"></b-form-checkbox-group>
        </b-form-group>
        <span class="error-inputs">{{ errors.first('days') }}</span>
      </div>
      <div class="form-group">
        <label for="time">{{ $t('date') }} - {{ $t('time') }}</label>
        <date-picker v-model="match.time" :config="options" name="time" autocomplete="off" v-validate="'required'" :state="validateState('time')"></date-picker>
        <span class="error-inputs">{{ errors.first('time') }}</span>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalDateGame')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    division: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      match: {},
      options: {
        format: 'HH:mm',
        useCurrent: false,
      },
      days: [
        {text: this.$t('monday'), value: 'lu'},
        {text: this.$t('tuesday'), value: 'ma'},
        {text: this.$t('wednesday'), value: 'mi'},
        {text: this.$t('thursday'), value: 'ju'},
        {text: this.$t('friday'), value: 'vi'},
        {text: this.$t('saturday'), value: 'sa'},
        {text: this.$t('sunday'), value: 'do'}
      ]
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const tournamentId = this.$route.params.tournamentId
          const divisionId = this.$route.params.divisionId
          const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}/schedule/store`
          this.$axios.post(path, this.match).then(() => {
            this.$root.$emit('bv::hide::modal', 'modalDateGame')
            this.$emit('updateSchedule', this.match)
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    init () {
      this.match = {
        days: this.division.game_date.split(','),
        time: this.division.game_time
      }
    }
  }
}
</script>
